import React from "react"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

const About = ({ data }) => {
  const {
    strapiAbout: { title, image, bio },
  } = data
  return (
    <>
      <Layout>
        <Seo title="About Us" description="This is our About Us page" />
        <section className="about-page">
          <div className="section-center about-center">
            <img
              src={image.localFile.url}
              alt={title}
              className="about-img-svg"
            />
            {/* <img src={image.publicURL} alt={title} className="about-img-svg" /> */}
            <article className="about-text">
              <Title title={title} />
              <p>{bio}</p>
              {/* <div className="about-stack">
                {topic.map(item => {
                  return <span key={item.id}>{item.detail}</span>
                })}
              </div> */}
            </article>
          </div>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    strapiAbout {
      bio
      id
      image {
        localFile {
          url
        }
      }
      title
    }
  }
`

export default About
